<template>
  <k-field-group language-prefix="instruction">
    <k-sidebar v-bind="$attrs"
               v-on="$listeners"
               :save-button-text="$t('global.applyFilter')"
               :title="$t('global.filterTitle', { module: $tc('instruction.title', 2) })"
               fixed
               right
               temporary
               width="300"
               @click:confirm="handleFilterClick">
      <ClientAutocomplete
          v-model="form.clientId"
          field="fields.clientName"
          :return-object="false"
          clearable
          hide-details/>
      <k-select class="mt-4"
                v-model="form.instructionTypeId"
                v-bind="$attrs"
                :items="instructionTypes"
                field="fields.instructionType"
                item-text="name"
                item-value="id"></k-select>
      <TemplateAutocomplete v-model="form.templateId"
                            field="actions.document.templateId"
                            :return-object="false"
                            clearable
                            hide-details/>
      <KCheckbox :value="form.isBillable"
                 @change="form.isBillable = $event === true ? 1 : undefined"
                 field="fields.isBillable"/>
      <KCheckbox :value="form.isActive"
                 @change="form.isActive = $event === true ? 1 : undefined"
                 field="fields.isActive"/>
      <KCheckbox :value="form.hasMemo" @change="form.hasMemo = $event === true ? 1 : undefined" field="fields.hasMemo"/>
      <KTextField v-model="form.city"
                  field="instruction.conditions.deliveryAddress.city"/>
      <KTextField v-model="form.orderType"
                  field="instruction.actions.fields.orderType"/>
    </k-sidebar>
  </k-field-group>
</template>

<script>
import KFieldGroup from '@/components/crud/fields/KFieldGroup.vue';
import KSidebar from '@/components/layout/KSidebar.vue';
import ClientAutocomplete from '@/components/autocompletes/Client.vue';
import TemplateAutocomplete from '@/components/autocompletes/Template.vue';
import KTextField from '@/components/crud/fields/KTextField.vue';
import KSelect from '@/components/crud/fields/KSelect.vue';
import KCheckbox from '@/components/crud/fields/KCheckbox.vue';

export default {
  name: 'InstructionFilter',
  components: {
    KCheckbox,
    KSelect,
    KTextField,
    ClientAutocomplete,
    KSidebar,
    KFieldGroup,
    TemplateAutocomplete,
  },
  inheritAttrs: false,
  props: {
    filters: {
      type: Object,
    },
    instructionTypes: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
  data() {
    return {
      form: {},
    };
  },
  watch: {
    filters: {
      handler(values) {
        this.form = { ...this.form, ...values };
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    handleFilterClick() {
      this.$emit('update:filters', this.form);
      this.$emit('input', false);
    },
    // this is needed to make sure no empty query param is used
    handleTemplateIdInput(value) {
      this.form.templateId = Number.isInteger(parseInt(value)) ? value : undefined;
    },
  },
};
</script>
