<template>
  <KCrudLayout :filters.sync="filters">
    <template #header>
      {{ $tc("instruction.instructionTypeTitles.complimentCard", 2) }}
    </template>
    <template #filters="{attrs, on}">
      <InstructionFilter v-bind="attrs" v-on="on" :instruction-types="instructionTypes"/>
    </template>
    <template #view.list>
      <resource
          :create-request="createHandler"
          :update-request="updateHandler"
          :delete-request="deleteHandler"
          :parameters="filters"
          :form-component="() => import('../components/forms/ComplimentCardForm.vue')"
          :index-request="indexHandler"
          :meta="{name: $tc('instruction.instructionTypeTitles.complimentCard', 1), namePlural: $tc('instruction.instructionTypeTitles.complimentCard', 2)}"
          :model-type="modelType"
          :show-request="showHandler"
          :table-content="tableContent"
          class="instruction-resource"
          ref="resource"
          :can-add="can('compliment-card.all')"
          :can-delete="can('compliment-card.all')"
          :can-update="can('compliment-card.all')"
      >
        <template #createToolbar>
          <v-btn dark text @click="trySaveCreateResource">{{ $t('actions.save') }}</v-btn>
        </template>
        <template #updateToolbar>
          <v-btn dark text @click="trySaveUpdateResource">{{ $t('actions.save') }}</v-btn>
        </template>
      </resource>
    </template>
  </KCrudLayout>
</template>

<script lang="js">
import Resource from '@/components/Resource.vue';
import eventBus from '@/eventBus.js';
import { create, index, remove, show, update } from '../api/endpoints/instruction.js';
import Instruction from '@/application/models/instruction.js';
import IsBlockedColumnType from '@/components/instruction/IsBlockedColumnType.vue';
import querySyncMixin from '@/application/mixins/querySyncMixin.js';
import InstructionFilter from '@/components/instruction/InstructionFilter.vue';
import KCrudLayout from '@/components/layout/KCrudLayout.vue';
import instructionTypesEnum from '@/application/enums/instructionTypes.js';
import { mapGetters } from "vuex";
import isRecursiveObjectEmpty from '@/application/util/isRecursiveEmptyObject.js';


export default {
  name: 'InstructionResource',
  mixins: [querySyncMixin],
  components: {
    KCrudLayout,
    InstructionFilter,
    Resource,
  },
  data: () => ({
    instruction: null,
    filters: { instructionTypeId: instructionTypesEnum.COMPLIMENT_CARD },
  }),
  computed: {
    ...mapGetters("profile", ["can"]),
    indexHandler: () => index,
    showHandler: () => show,
    deleteHandler: () => remove,
    updateHandler: () => update,
    modelType: () => Instruction,
    tableContent() {
      return [
        {
          text: this.$t('instruction.fields.id'),
          align: 'left',
          sortable: false,
          value: 'id',
        },
        {
          text: this.$t('instruction.fields.clientName'),
          align: 'left',
          sortable: true,
          value: 'clientName',
        },
        {
          text: this.$t('instruction.fields.gridHeaders.isActive'),
          align: 'left',
          sortable: true,
          value: 'isActive',
          columnType: 'checkbox',
        },
        {
          text: this.$t('instruction.fields.gridHeaders.isBillable'),
          align: 'left',
          sortable: true,
          value: 'isBillable',
          columnType: 'checkbox',
        },
        {
          text: this.$t('instruction.fields.gridHeaders.isBlocked'),
          align: 'left',
          sortable: true,
          value: 'isBlocked',
          columnType: IsBlockedColumnType,
        },
        {
          text: this.$t('instruction.fields.gridHeaders.hasDocument'),
          align: 'left',
          sortable: true,
          value: 'hasDocument',
          columnType: 'checkbox',
        },
        {
          text: this.$t('instruction.fields.gridHeaders.hasMemo'),
          align: 'left',
          sortable: true,
          value: 'hasMemo',
          columnType: 'checkbox',
        },
        {
          text: this.$t('instruction.conditions.deliveryAddress.houseNumber'),
          align: 'left',
          sortable: true,
          value: 'houseNumber',
        },
        {
          text: this.$t('instruction.conditions.deliveryAddress.city'),
          align: 'left',
          sortable: true,
          value: 'city',
        },
        {
          text: this.$t('instruction.conditions.deliveryAddress.postalCode'),
          align: 'left',
          sortable: true,
          value: 'postalCode',
        },
        {
          text: this.$t('instruction.actions.fields.orderType'),
          align: 'left',
          sortable: true,
          value: 'orderType',
        },
        {
          text: this.$t('instruction.actions.fields.shippingMethod'),
          align: 'left',
          sortable: true,
          value: 'shippingMethod',
        },
        {
          text: this.$t('instruction.fields.createdAt'),
          align: 'left',
          sortable: true,
          value: 'createdAt',
        },
        {
          text: this.$t('instruction.fields.updatedAt'),
          align: 'left',
          sortable: true,
          value: 'updatedAt',
        },
      ];
    },
    instructionTypes() {
      return Object.keys(instructionTypesEnum).map((instructionType) => ({
        name: this.$t(`instruction.instructionTypes.${instructionType}`),
        id: instructionTypesEnum[instructionType],
      }));
    },
  },
  created() {
    this.setBreadCrumbs();
  },
  methods: {
    setBreadCrumbs() {
      eventBus.$emit('setBreadcrumbs', [
        {
          exact: true,
          to: { name: 'home' },
          text: this.$t('global.dashboard'),
        },
        {
          exact: true,
          to: { name: this.$route.name },
          text: this.$tc("instruction.instructionTypeTitles.complimentCard", 2),
        },
      ]);
    },
    createHandler(...args) {
      return create(...args).then((response) => {
        this.instruction = response.data.data;
      });
    },
    trySaveUpdateResource() {
      this.trySaveResource(this.$refs.resource.$refs.resourceList.updateHandler, this.$refs.resource.updateForm.values);
    },
    trySaveCreateResource() {
      this.trySaveResource(this.$refs.resource.$refs.resourceList.createHandler, this.$refs.resource.createForm.values);
    },
    trySaveResource(callback, values) {
      if (!values.instructionType) {
        values.instructionType = {
          id: instructionTypesEnum.COMPLIMENT_CARD,
          name: '',
        };
      }

      const hasNoConditions = isRecursiveObjectEmpty(values.conditions);
      const appliesToAllClients = !values.clientId;

      if (appliesToAllClients && hasNoConditions) {
        return eventBus.$emit('confirm', {
          title: this.$t('instruction.confirmationDialog.title'),
          body: this.$t('instruction.confirmationDialog.both'),
          confirmCallback: callback,
        });
      }

      if (appliesToAllClients) {
        return eventBus.$emit('confirm', {
          title: this.$t('instruction.confirmationDialog.title'),
          body: this.$t('instruction.confirmationDialog.noClientId'),
          confirmCallback: callback,
        });
      }

      if (hasNoConditions) {
        return eventBus.$emit('confirm', {
          title: this.$t('instruction.confirmationDialog.title'),
          body: this.$t('instruction.confirmationDialog.noAddress'),
          confirmCallback: callback,
        });
      }

      callback();
    },
  },
};
</script>
