import { getPaginated } from '@/api/implementation/app/wrapper.js';
import { destroy, get, post, put } from '@/api/implementation/app';

function index(page, perPage, search, sortBy, descending, params) {
  return getPaginated('instruction', page, perPage, search, sortBy, descending, params);
}

function show(instructionId) {
  return get(`instruction/${instructionId}`);
}

function create(instruction) {
  return post('instruction', instruction);
}

function update(instruction) {
  return put(`instruction/${instruction.id}`, instruction);
}

function remove(instructionId) {
  return destroy(`instruction/${instructionId}`);
}

export {
  index,
  show,
  create,
  update,
  remove,
};
