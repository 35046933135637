<template>
  <div>
    <v-icon color="gray" v-if="content === true">fa-check-square</v-icon>
    <v-icon color="gray" v-else-if="content === false">fas fa-times-circle</v-icon>
  </div>
</template>

<script>
export default {
  name: 'IsBlockedColumnType',
  props: {
    content: {
      type: Boolean,
    },
  },
};
</script>

<style scoped>

</style>
