<template>
  <KPaginatedAutocomplete :paginator="autocomplete" v-bind="$attrs" v-on="$listeners" ref="autocomplete"/>
</template>

<script>
import { autocomplete } from '@/api/endpoints/template.js';
import KPaginatedAutocomplete from '@/components/crud/fields/KPaginatedAutocomplete.vue';

export default {
  name: 'ClientAutocomplete',
  components: { KPaginatedAutocomplete },
  methods: {
    async autocomplete(page, perPage, search, sortBy, descending, params) {
      const response = await autocomplete(page, perPage, search, sortBy, descending, params);
      response.data.data = response.data.data.map(item => {
        return {
          ...item,
          name: `${item.name} (${item.id})`,
        }
      })
      return response
    },
  },
};
</script>
