<template>
  <v-container fluid>
    <h1 class="text-h1 mb-3">
      <slot name="header"/>
    </h1>
    <slot :attrs="{value: isFilterBarOpen, filters}"
          :on="{ 'update:filters': handleFiltersUpdate, input: handleFilterInput }"
          name="filters"/>

    <v-row align="center" no-gutters>
      <v-col v-if=" withFilters" class="searchbar">
        <v-row align="center" no-gutters>
          <v-btn-toggle>
            <v-btn v-if="withFilters" height="35" icon width="60" @click="toggleFilterBar">
              <v-badge :value="hasFilters" color="info" dot>
                <v-icon color="grey darken-1">fa-filter</v-icon>
              </v-badge>
            </v-btn>
          </v-btn-toggle>
        </v-row>
      </v-col>
    </v-row>

    <slot name="view.list"/>
  </v-container>
</template>

<script>
import { isEmpty } from '@/application/util/isEmpty.js';

export default {
  name: 'KCrudLayout',
  data: () => ({
    view: 0,
    isFilterBarOpen: false,
  }),
  props: {
    filters: {
      type: Object,
      default: () => {
      },
    },
  },
  computed: {
    viewSlots() {
      return Object.keys(this.$slots).filter((slot) => slot.substr(0, 5) === 'view.');
    },
    withFilters() {
      return this.$scopedSlots.filters;
    },
    hasFilters() {
      return Object.values(this.filters).some(val => !this.isEmpty(val));
    },
  },
  methods: {
    toggleFilterBar(to = null) {
      this.isFilterBarOpen = to === null ? !this.isFilterBarOpen : to;
    },
    handleFiltersUpdate(event) {
      return this.$emit('update:filters', event);
    },
    handleFilterInput(event) {
      return this.toggleFilterBar(event);
    },
    isEmpty,
  },
};
</script>
