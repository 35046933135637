export default function isRecursiveObjectEmpty(obj) {
  for (let key in obj) {
    if (typeof obj[key] === 'object' && obj[key] !== null) {
      if (!isRecursiveObjectEmpty(obj[key])) {
        return false;
      }
    } else if (obj[key] !== null && obj[key] !== false && obj[key] !== '') {
      return false;
    }
  }
  return true;
}

