import Model from './model.js';

export default class Instruction extends Model {
  id = 0;
  instructionType = null;
  isActive = true;
  isBillable = false;
  orderType = null;
  used = 0;
  clientId = null;

  conditions = {
    deliveryAddress: {},
    hasComplimentCardText: false,
  };
  actions = {
    block: null,
    fields: {},
    document: {},
    termsOfDelivery: "",
  };

  mapForRequest() {
    return {
      instructionTypeId: this.instructionType?.id,
      clientId: this.clientId,
      isActive: this.isActive,
      isBillable: this.isBillable,
      conditions: this.prepareConditionsKeys(this.conditions),
      actions: {
        block: this.actions.block,
        fields: this.prepareFieldKeys(this.actions.fields),
        termsOfDelivery: this.actions.termsOfDelivery,
        document: {
          templateId: this.actions.document.templateId || undefined,
          sourceFileName: this.actions.document.sourceFileName || undefined,
          sourceFolder: this.actions.document.sourceFolder || undefined,
          fileName: this.actions.document.fileName || undefined,
          folder: this.actions.document.folder || undefined,
        },
      },
    };
  }

  mapResponse(data) {
    data.actions.fields = this.mapActionFieldKeys(data.actions.fields);
    data.conditions = this.mapConditionsKeys(data.conditions);
    data.conditions.hasComplimentCardText = !!parseInt(data.conditions.hasComplimentCardText);
    return super.mapResponse(data);
  }

  mapActionFieldKeys(fields = {}) {
    let output = {};
    Object.keys(fields).forEach(key => {
      output[key] = fields[key];
    });
    return output;
  }

  prepareFieldKeys(fields = {}) {
    let output = {};
    Object.keys(fields).forEach(key => {
      output[key] = fields[key];
    });
    return output;
  }

  mapConditionsKeys(conditions = {}) {
    let output = {
      deliveryAddress: {},
    };

    Object.keys(conditions).forEach(key => {
      const splittedKeyName = key.split('.'),
        keyName = splittedKeyName[0];

      if (keyName && splittedKeyName.length === 1) {
        output[keyName] = conditions[key];
      }

      if (keyName && splittedKeyName.length === 2) {
        const explodedKey = splittedKeyName[1],
          result = {};
        result[explodedKey] = conditions[key];

        output[keyName] = {
          ...output[keyName],
          ...result,
        };
      }
    });

    return output;
  }

  prepareConditionsKeys(conditions = {}) {
    let output = {};

    Object.keys(conditions).forEach(conditionKey => {
      if (typeof conditions[conditionKey] === 'object') {
        Object.keys(conditions[conditionKey]).forEach(parameterKey => {
          const keyName = `${conditionKey}.${parameterKey}`;
          output[keyName] = conditions[conditionKey][parameterKey];
        });
      } else {
        output[conditionKey] = conditions[conditionKey];
      }
    });

    return output;
  }
}
